// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconOk from "../../../../../../../styleguide/icons/IconOk.res.js";
import * as IconClose from "../../../../../../../styleguide/icons/IconClose.res.js";
import * as IconTools from "../../../../../../../styleguide/icons/IconTools.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconServers from "../../../../../../../styleguide/icons/IconServers.res.js";
import * as IconTechnologies from "../../../../../../../styleguide/icons/IconTechnologies.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as IconInternetExchange from "../../../../../../../styleguide/icons/IconInternetExchange.res.js";
import * as IconColocationFacility from "../../../../../../../styleguide/icons/IconColocationFacility.res.js";
import * as LocationShowContentFacilitiesScss from "./LocationShowContentFacilities.scss";

var css = LocationShowContentFacilitiesScss;

function fromString(facilityType) {
  switch (facilityType) {
    case "bare-metal" :
        return "BareMetal";
    case "colocation" :
        return "Colocation";
    case "iaas" :
        return "IaaS";
    case "internet-exchange" :
        return "Internet";
    default:
      return "Fallback";
  }
}

function toId(facilityType) {
  switch (facilityType) {
    case "BareMetal" :
        return "BareMetal";
    case "Colocation" :
        return "Colocation";
    case "IaaS" :
        return "Iaas";
    case "Internet" :
        return "Internet";
    case "Fallback" :
        return "Fallback";
    
  }
}

function toIcon(facilityType, opacity) {
  switch (facilityType) {
    case "BareMetal" :
        return JsxRuntime.jsx(IconServers.make, {
                    size: "XL",
                    color: "Teal",
                    opacity: opacity
                  });
    case "Colocation" :
        return JsxRuntime.jsx(IconColocationFacility.make, {
                    size: "XL",
                    opacity: opacity
                  });
    case "IaaS" :
        return JsxRuntime.jsx(IconTechnologies.make, {
                    size: "XL",
                    color: "Teal",
                    opacity: opacity
                  });
    case "Internet" :
        return JsxRuntime.jsx(IconInternetExchange.make, {
                    size: "XL",
                    opacity: opacity
                  });
    case "Fallback" :
        return JsxRuntime.jsx(IconTools.make, {
                    size: "XL",
                    color: "Teal",
                    opacity: opacity
                  });
    
  }
}

function LocationShowContentFacilities(props) {
  var facilities = props.facilities;
  if (facilities.length !== 0) {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(Belt_Array.mapWithIndex(facilities, (function (index, facility) {
                            var id = toId(fromString(facility.slug));
                            var facilityTypeId = "facilityType" + id + "}";
                            var facilityIconId = "facilityTypeIcon" + id + "}";
                            var facilityTypeNameId = "facilityTypeName" + id + "}";
                            var facilityType = fromString(facility.slug);
                            var opacity = facility.live ? "1.0" : "0.5";
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  toIcon(facilityType, opacity),
                                                  facility.live ? JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(IconOk.make, {
                                                                size: "XXS",
                                                                color: "White"
                                                              }),
                                                          className: css.active
                                                        }) : JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(IconClose.make, {
                                                                size: "XXS",
                                                                color: "White"
                                                              }),
                                                          className: css.inactive
                                                        })
                                                ],
                                                className: css.facilityIcon,
                                                id: facilityIconId
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: facility.name,
                                                className: css.facilityName,
                                                id: facilityTypeNameId
                                              })
                                        ],
                                        className: css.facilityType,
                                        id: facilityTypeId
                                      }, String(index));
                          })))
              });
  } else {
    return null;
  }
}

var make = LocationShowContentFacilities;

export {
  css ,
  fromString ,
  toId ,
  toIcon ,
  make ,
}
/* css Not a pure module */
