// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentMapTabScss from "./LocationShowContentMapTab.scss";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = LocationShowContentMapTabScss;

function LocationShowContentMapTab(props) {
  var $$location = props.location;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: $$location.name + " Map",
                              className: css.title
                            }),
                        JsxRuntime.jsx(ComponentsImportsLoadableJsx.LocationShowContentMap, {
                              provider: props.provider,
                              location: $$location
                            })
                      ],
                      className: css.mapContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Address",
                              className: css.title
                            }),
                        JsxRuntime.jsx("div", {
                              children: $$location.fullAddress,
                              className: css.address
                            })
                      ],
                      className: css.addressContainer
                    })
              ],
              className: css.container
            });
}

var make = LocationShowContentMapTab;

export {
  css ,
  make ,
}
/* css Not a pure module */
