// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as IconOk from "../../../../../styleguide/icons/IconOk.res.js";
import * as IconPin from "../../../../../styleguide/icons/IconPin.res.js";
import * as IconWeb from "../../../../../styleguide/icons/IconWeb.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as $$Location from "../../../../../models/Location.res.js";
import * as IconPhone from "../../../../../styleguide/icons/IconPhone.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconDownload from "../../../../../styleguide/icons/IconDownload.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContact from "../contact/LocationShowContact.res.js";
import * as Api_LocationBrochure from "../../../../../api/location-brochures/Api_LocationBrochure.res.js";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as LocationShowSidebarStat from "./components/LocationShowSidebarStat.res.js";
import * as LocationShowSidebarScss from "./LocationShowSidebar.scss";

var css = LocationShowSidebarScss;

var initialState = {
  showSignIn: false
};

function reducer(state, action) {
  return {
          showSignIn: !state.showSignIn
        };
}

function LocationShowSidebar(props) {
  var userLoginStatus = props.userLoginStatus;
  var locationBrochures = props.locationBrochures;
  var provider = props.provider;
  var $$location = props.location;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var notifyAndDownload = function (locationBrochure) {
    $$Promise.wait(Api_LocationBrochure.notifyDownload(locationBrochure.id), (function (x) {
            if (x.TAG === "Ok") {
              return Url.visitBlank(locationBrochure.privateDownload);
            } else {
              return SentryLogger.error1({
                          rootModule: "LocationShowSidebar",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "LocationShowSidebar.make"
                        }, "LocationShowSidebar" + "::LocationBrochure::notifyDownload::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var x = locationBrochures.length;
  var tmp;
  if (x === 1) {
    var brochure = Belt_Option.getExn(Belt_Array.get(locationBrochures, 0));
    tmp = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Button.make, {
                size: "SM",
                color: "Teal",
                expanded: true,
                disabled: brochure.pdfFile === "",
                visuallyDisabled: brochure.pdfFile.length === 0,
                onClick: (function (param) {
                    if (userLoginStatus === "LoggedIn") {
                      return notifyAndDownload(brochure);
                    } else {
                      return dispatch("ToggleSignInModal");
                    }
                  }),
                children: "Download Brochure"
              }),
          className: css.downloadBrochuresContainer
        });
  } else {
    tmp = x > 1 ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsxs(Popover.make, {
                  id: "brochures",
                  children: [
                    JsxRuntime.jsx(Popover.Trigger.make, {
                          children: JsxRuntime.jsx(Button.make, {
                                size: "SM",
                                color: "Teal",
                                expanded: true,
                                className: css.ctaButton,
                                children: "Download Brochures",
                                iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                          size: "SM",
                                          color: "White"
                                        }))
                              })
                        }),
                    JsxRuntime.jsx(Popover.Body.make, {
                          position: {
                            TAG: "Below",
                            _0: "RightEdge"
                          },
                          className: css.popoverBody,
                          children: Belt_Array.map(locationBrochures, (function (brochure) {
                                  return JsxRuntime.jsxs("a", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: brochure.title,
                                                      className: css.brochureLink
                                                    }),
                                                JsxRuntime.jsx(IconDownload.make, {
                                                      size: "SM",
                                                      color: "DarkGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              href: "#",
                                              onClick: (function (param) {
                                                  if (userLoginStatus === "LoggedIn") {
                                                    return notifyAndDownload(brochure);
                                                  } else {
                                                    return dispatch("ToggleSignInModal");
                                                  }
                                                })
                                            });
                                }))
                        })
                  ]
                }),
            className: css.downloadBrochuresContainer
          }) : null;
  }
  var x$1 = $$location.grossBuildingSize;
  var x$2 = $$location.grossColocationSpace;
  var x$3 = $$location.totalPowerMw;
  var x$4 = $$location.nearestAirport;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Picture.make, {
                                            src: provider.logo,
                                            large: [
                                              120,
                                              80
                                            ],
                                            crop: "Fit"
                                          }),
                                      className: css.logo
                                    }),
                                JsxRuntime.jsx(Picture.make, {
                                      src: $$location.image,
                                      large: [
                                        320,
                                        240
                                      ],
                                      className: css.locationImage,
                                      fallbackSrc: $$Location.imageNotFoundUrl
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("div", {
                                            children: "View images",
                                            className: css.sidebarImageOverlayText
                                          }),
                                      className: css.sidebarImageOverlay
                                    }),
                                provider.agreement && provider.showAgreementCheck ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(IconOk.make, {
                                              size: "XXS",
                                              color: "White"
                                            }),
                                        className: css.agreementCheck
                                      }) : null
                              ],
                              className: css.sidebarImage,
                              id: "locationSidebarImage",
                              onClick: props.onClick
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(IconPin.make, {
                                                    size: "SM",
                                                    color: "DarkerGray"
                                                  }),
                                              className: css.sidebarIcon
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.fullAddress,
                                              className: css.sidebarAddress,
                                              id: "sidebarAddress"
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.sidebarData,
                                            css.sidebarAddress
                                          ]),
                                      id: "locationsSidebarContactAddress"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(IconPhone.make, {
                                                    size: "SM",
                                                    color: "DarkerGray"
                                                  }),
                                              className: css.sidebarIcon,
                                              id: "sidebarPhone"
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: $$location.phone,
                                              className: css.sidebarPhone,
                                              id: "sidebarPhone"
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.sidebarData,
                                            css.sidebarPhone
                                          ]),
                                      id: "locationsSidebarContactPhone"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(IconWeb.make, {
                                                    size: "SM",
                                                    color: "DarkerGray"
                                                  }),
                                              className: css.sidebarIcon,
                                              id: "sidebarProvider"
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(A.make, {
                                                    id: "sidebarProviderLink",
                                                    href: Routes_Provider.show(provider.slug),
                                                    className: css.sidebarProviderLink,
                                                    children: "View " + provider.name
                                                  }),
                                              className: css.sidebarProvider,
                                              id: "sidebarProvider"
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.sidebarData,
                                            css.sidebarProvider
                                          ]),
                                      id: "locationsSidebarContactProvider"
                                    }),
                                tmp
                              ],
                              className: css.sidebarContact,
                              id: "locationsSidebarContact"
                            })
                      ],
                      className: css.sidebarSummary,
                      id: "locationSidebarSummary"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        x$1 === "0" || x$1 === "0.0" || x$1 === "" ? null : JsxRuntime.jsx(LocationShowSidebarStat.make, {
                                icon: "TotalSpace",
                                stat: x$1
                              }),
                        x$2 === "0" || x$2 === "0.0" || x$2 === "" ? null : JsxRuntime.jsx(LocationShowSidebarStat.make, {
                                icon: "ColocationSpace",
                                stat: x$2
                              }),
                        x$3 === "0" || x$3 === "0.0" || x$3 === "" ? null : JsxRuntime.jsx(LocationShowSidebarStat.make, {
                                icon: "Power",
                                stat: x$3
                              }),
                        JsxRuntime.jsx(LocationShowSidebarStat.make, {
                              icon: "Products",
                              stat: String($$location.totalProducts)
                            }),
                        x$4 === "0" || x$4 === "0.0" || x$4 === "" ? null : JsxRuntime.jsx(LocationShowSidebarStat.make, {
                                icon: "Airport",
                                stat: x$4
                              })
                      ],
                      className: css.sidebarStats,
                      id: "locationsSidebarStats"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationShowContact.make, {
                            provider: provider,
                            location: $$location
                          }),
                      className: css.contactSidebar,
                      id: "contactSidebar"
                    }),
                match[0].showSignIn ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch("ToggleSignInModal");
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to download a Location Brochure."
                      }) : null
              ],
              className: css.locationSidebar,
              id: "locationSidebar"
            });
}

var make = LocationShowSidebar;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
