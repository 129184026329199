// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconAirplane(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Airplane";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M15.7091972,0.29082169 C16.044061,0.68659556 16.0897243,1.24981223 15.8461869,1.98047168 C15.6026496,2.71113114 15.1916803,3.36568024 14.6132791,3.94411897 L12.7753331,5.78218417 L14.6018633,13.7281058 C14.639916,13.8727154 14.5942527,13.9982975 14.4648735,14.104852 L13.0036494,15.2008412 C12.9503756,15.2465074 12.8780754,15.2693405 12.7867489,15.2693405 C12.7563067,15.2693405 12.7296698,15.265535 12.7068382,15.257924 C12.5926801,15.2350909 12.5127694,15.1742026 12.4671061,15.0752591 L9.2820942,9.2756497 L6.32539848,12.2325372 L6.93043659,14.4473487 C6.96848931,14.5767363 6.93804714,14.6947073 6.83911009,14.8012618 L5.74319199,15.897251 C5.67469711,15.9657503 5.58717587,16 5.48062828,16 L5.45779665,16 C5.34363852,15.9847779 5.25231201,15.9353062 5.18381713,15.8515848 L3.02622838,12.9746132 L0.14944338,10.8168845 C0.06572741,10.7636072 0.01625889,10.6760803 0.0010378,10.5543037 C-0.00657274,10.4553603 0.0276747,10.3602223 0.10378012,10.2688899 L1.19969822,9.1614842 C1.2681931,9.0929848 1.35571434,9.0587352 1.46226193,9.0587352 C1.50792518,9.0587352 1.53836735,9.0625407 1.55358844,9.0701517 L3.76825625,9.6752291 L6.72495195,6.7183416 L0.92571869,3.53312303 C0.8191711,3.47223474 0.75448149,3.38090231 0.73164986,3.25912573 C0.71642878,3.13734916 0.75067622,3.03460017 0.83439219,2.95087877 L2.29561631,1.48955986 C2.40216391,1.39061639 2.51632204,1.36017225 2.63809072,1.39822743 L10.2296067,3.21345952 L12.0561369,1.38681088 C12.6345381,0.80837214 13.2890447,0.3973762 14.0196568,0.15382304 C14.7502688,-0.08973011 15.313449,-0.04406389 15.7091972,0.29082169 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconAirplane;

export {
  make ,
}
/* Icon Not a pure module */
