// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconTools(props) {
  var __opacity = props.opacity;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Tools";
  var opacity = __opacity !== undefined ? __opacity : "1.0";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M3,3 L3,57 L57,57 L57,3 L3,3 Z M2,-4.26325641e-13 L58,-4.26325641e-13 C59.1045695,-4.32414876e-13 60,0.8954305 60,2 L60,58 C60,59.1045695 59.1045695,60 58,60 L2,60 C0.8954305,60 -1.19699688e-15,59.1045695 -1.33226763e-15,58 L3.33066907e-16,2 C1.97796157e-16,0.8954305 0.8954305,-4.27455003e-13 2,-4.26325641e-13 Z",
                                                    fill: "#98A0B0",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "54",
                                                    fill: "#98A0B0",
                                                    x: "3",
                                                    y: "9"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "12",
                                                    cy: "45",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("polyline", {
                                                    id: "Line",
                                                    points: "12 45 22.5 33 34.5033752 36 48 27",
                                                    stroke: "#18C3BB",
                                                    strokeLinecap: "square",
                                                    strokeWidth: "1.6"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval-Copy",
                                                    cx: "48",
                                                    cy: "27",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval-Copy",
                                                    cx: "34.5",
                                                    cy: "36",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval-Copy",
                                                    cx: "22.5",
                                                    cy: "33",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  })
                                            ],
                                            id: "Group-16"
                                          }),
                                      id: "Icon-/-Big-/-Tools",
                                      transform: "translate(1090.000000, 855.000000)"
                                    }),
                                id: "Agent-Program",
                                fillRule: "nonzero",
                                transform: "translate(-1090.000000, -855.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          opacity: opacity,
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconTools;

export {
  make ,
}
/* Icon Not a pure module */
