// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../../utils/Url.res.js";
import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../../../models/Location.res.js";
import * as IconChevron from "../../../../../../styleguide/icons/IconChevron.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowRelatedLocationScss from "./LocationShowRelatedLocation.scss";

var css = LocationShowRelatedLocationScss;

function LocationShowRelatedLocation(props) {
  var $$location = props.location;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Picture.make, {
                                    src: $$location.imageUrl,
                                    large: [
                                      120,
                                      90
                                    ],
                                    className: css.image,
                                    fallbackSrc: $$Location.imageNotFoundUrl
                                  }),
                              className: css.relatedLocationImage
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: $$location.providerName,
                                      className: css.relatedLocationSummaryProvider
                                    }),
                                JsxRuntime.jsx("a", {
                                      children: $$location.name,
                                      className: css.relatedLocationLink,
                                      href: $$location.url
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: $$location.fullAddress,
                                      className: css.relatedLocationSummaryAddress
                                    })
                              ],
                              className: css.relatedLocationSummary
                            })
                      ],
                      className: css.relatedLocationLeft
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconChevron.make, {
                            color: "DarkGray",
                            direction: "Right",
                            className: css.relatedLocationChevron
                          }),
                      className: css.relatedLocationRight
                    })
              ],
              className: css.relatedLocationContainer,
              onClick: (function (param) {
                  Url.visit($$location.url);
                })
            });
}

var make = LocationShowRelatedLocation;

export {
  css ,
  make ,
}
/* css Not a pure module */
