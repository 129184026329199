// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentTabsScss from "./LocationShowContentTabs.scss";

var css = LocationShowContentTabsScss;

function LocationShowContentTabs(props) {
  var onClick = props.onClick;
  var currentTab = props.currentTab;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(Belt_Array.mapWithIndex(props.tabs, (function (index, tab) {
                          return JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("strong", {
                                            children: tab.name
                                          }),
                                      className: currentTab === tab.tab ? css.tabActive : css.tab,
                                      onClick: (function (param) {
                                          onClick(tab.tab);
                                        })
                                    }, String(index));
                        })))
            });
}

var make = LocationShowContentTabs;

export {
  css ,
  make ,
}
/* css Not a pure module */
