// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Textarea from "../../../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../../../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../../../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as ResponseMessage from "../../../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as EmailValidator from "email-validator";
import * as Api_LocationLead from "../../../../../../../api/location-leads/Api_LocationLead.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as LocationShowContactFormScss from "./LocationShowContactForm.scss";

var css = LocationShowContactFormScss;

var spaces = [
  "OneU",
  "TwoU",
  "FourU",
  "TenU",
  "SixteenU",
  "TwentyU",
  "FortyTwoU",
  "FortyFiveU",
  "FortyEightU",
  "OpenCompute",
  "PrivateCage",
  "PrivateSuite",
  "Wholesale",
  "Unknown"
];

function spaceToString(colocationSpace) {
  switch (colocationSpace) {
    case "OneU" :
        return "1U - Shared Rack";
    case "TwoU" :
        return "2U - Shared Rack";
    case "FourU" :
        return "4U - Shared Rack";
    case "TenU" :
        return "10U-12U - Quarter Rack";
    case "SixteenU" :
        return "16U - Third Rack";
    case "TwentyU" :
        return "20U-26U - Half Rack";
    case "FortyTwoU" :
        return "42U Cabinet (Standard)";
    case "FortyFiveU" :
        return "45U Cabinet";
    case "FortyEightU" :
        return "48U-52U Cabinet";
    case "OpenCompute" :
        return "Open Compute Rack";
    case "PrivateCage" :
        return "Private Cage";
    case "PrivateSuite" :
        return "Private Suite";
    case "Wholesale" :
        return "Wholesale";
    case "Unknown" :
        return "Unknown";
    
  }
}

var validators_quantity = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.quantity;
      if (x >= 1) {
        return {
                TAG: "Ok",
                _0: input.quantity
              };
      } else {
        return {
                TAG: "Error",
                _0: "Quantity must be greater than 0."
              };
      }
    })
};

var validators_colocationSpace = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.colocationSpace.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.colocationSpace
              };
      } else {
        return {
                TAG: "Error",
                _0: "Colocation Space is required"
              };
      }
    })
};

var validators_businessName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.businessName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.businessName
              };
      } else {
        return {
                TAG: "Error",
                _0: "Business Name is required"
              };
      }
    })
};

var validators_headquartersAddress = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.headquartersAddress.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.businessName
              };
      } else {
        return {
                TAG: "Error",
                _0: "Address is required"
              };
      }
    })
};

var validators_contactName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.contactName.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.contactName
              };
      } else {
        return {
                TAG: "Error",
                _0: "Name is required"
              };
      }
    })
};

var validators_businessEmail = {
  strategy: "OnFirstChange",
  validate: (function (input) {
      if (EmailValidator.validate(input.businessEmail)) {
        return {
                TAG: "Ok",
                _0: input.businessEmail
              };
      } else {
        return {
                TAG: "Error",
                _0: "A valid email is required"
              };
      }
    })
};

var validators_phone = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.phone.length;
      if (match !== 0) {
        return {
                TAG: "Ok",
                _0: input.phone
              };
      } else {
        return {
                TAG: "Error",
                _0: "Phone number is required"
              };
      }
    })
};

var validators = {
  quantity: validators_quantity,
  colocationSpace: validators_colocationSpace,
  businessName: validators_businessName,
  headquartersAddress: validators_headquartersAddress,
  contactName: validators_contactName,
  businessEmail: validators_businessEmail,
  phone: validators_phone,
  note: undefined
};

function initialFieldsStatuses(_input) {
  return {
          quantity: "Pristine",
          colocationSpace: "Pristine",
          businessName: "Pristine",
          headquartersAddress: "Pristine",
          contactName: "Pristine",
          businessEmail: "Pristine",
          phone: "Pristine",
          note: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.quantity;
  var tmp;
  tmp = typeof match !== "object" ? validators.quantity.validate(input) : match._0;
  var match$1 = fieldsStatuses.colocationSpace;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.colocationSpace.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.businessName;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.businessName.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.headquartersAddress;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.headquartersAddress.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.contactName;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.contactName.validate(input) : match$4._0;
  var match$5 = fieldsStatuses.businessEmail;
  var tmp$5;
  tmp$5 = typeof match$5 !== "object" ? validators.businessEmail.validate(input) : match$5._0;
  var match$6 = fieldsStatuses.phone;
  var tmp$6;
  tmp$6 = typeof match$6 !== "object" ? validators.phone.validate(input) : match$6._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.note
  };
  var quantityResult = tmp;
  var quantityResult$1;
  if (quantityResult.TAG === "Ok") {
    var colocationSpaceResult = tmp$1;
    if (colocationSpaceResult.TAG === "Ok") {
      var businessNameResult = tmp$2;
      if (businessNameResult.TAG === "Ok") {
        var headquartersAddressResult = tmp$3;
        if (headquartersAddressResult.TAG === "Ok") {
          var contactNameResult = tmp$4;
          if (contactNameResult.TAG === "Ok") {
            var businessEmailResult = tmp$5;
            if (businessEmailResult.TAG === "Ok") {
              var phoneResult = tmp$6;
              if (phoneResult.TAG === "Ok") {
                var noteResult = match_0;
                if (noteResult.TAG === "Ok") {
                  return {
                          TAG: "Valid",
                          output: {
                            quantity: quantityResult._0,
                            colocationSpace: colocationSpaceResult._0,
                            businessName: businessNameResult._0,
                            headquartersAddress: headquartersAddressResult._0,
                            contactName: contactNameResult._0,
                            businessEmail: businessEmailResult._0,
                            phone: phoneResult._0,
                            note: noteResult._0
                          },
                          fieldsStatuses: {
                            quantity: {
                              TAG: "Dirty",
                              _0: quantityResult,
                              _1: "Shown"
                            },
                            colocationSpace: {
                              TAG: "Dirty",
                              _0: colocationSpaceResult,
                              _1: "Shown"
                            },
                            businessName: {
                              TAG: "Dirty",
                              _0: businessNameResult,
                              _1: "Shown"
                            },
                            headquartersAddress: {
                              TAG: "Dirty",
                              _0: headquartersAddressResult,
                              _1: "Shown"
                            },
                            contactName: {
                              TAG: "Dirty",
                              _0: contactNameResult,
                              _1: "Shown"
                            },
                            businessEmail: {
                              TAG: "Dirty",
                              _0: businessEmailResult,
                              _1: "Shown"
                            },
                            phone: {
                              TAG: "Dirty",
                              _0: phoneResult,
                              _1: "Shown"
                            },
                            note: {
                              TAG: "Dirty",
                              _0: noteResult,
                              _1: "Hidden"
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                quantityResult$1 = quantityResult;
              } else {
                quantityResult$1 = quantityResult;
              }
            } else {
              quantityResult$1 = quantityResult;
            }
          } else {
            quantityResult$1 = quantityResult;
          }
        } else {
          quantityResult$1 = quantityResult;
        }
      } else {
        quantityResult$1 = quantityResult;
      }
    } else {
      quantityResult$1 = quantityResult;
    }
  } else {
    quantityResult$1 = quantityResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            quantity: {
              TAG: "Dirty",
              _0: quantityResult$1,
              _1: "Shown"
            },
            colocationSpace: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            businessName: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            headquartersAddress: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            contactName: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            businessEmail: {
              TAG: "Dirty",
              _0: tmp$5,
              _1: "Shown"
            },
            phone: {
              TAG: "Dirty",
              _0: tmp$6,
              _1: "Shown"
            },
            note: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurQuantityField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.quantity, validators_quantity, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: status,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurColocationSpaceField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.colocationSpace, validators_colocationSpace, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: status,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurBusinessNameField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.businessName, validators_businessName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: status,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurHeadquartersAddressField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.headquartersAddress, validators_headquartersAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: status,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurContactNameField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contactName, validators_contactName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: status,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurBusinessEmailField" :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.businessEmail, validators_businessEmail, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: status,
                                  phone: init.phone,
                                  note: init.note
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPhoneField" :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.phone, validators_phone, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: status,
                                  note: init.note
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNoteField" :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.note, state.fieldsStatuses.note, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  quantity: init.quantity,
                                  colocationSpace: init.colocationSpace,
                                  businessName: init.businessName,
                                  headquartersAddress: init.headquartersAddress,
                                  contactName: init.contactName,
                                  businessEmail: init.businessEmail,
                                  phone: init.phone,
                                  note: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateQuantityField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.quantity, state.submissionStatus, validators_quantity, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: status,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateColocationSpaceField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.colocationSpace, state.submissionStatus, validators_colocationSpace, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: status,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateBusinessNameField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.businessName, state.submissionStatus, validators_businessName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: status,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateHeadquartersAddressField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.headquartersAddress, state.submissionStatus, validators_headquartersAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: status,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateContactNameField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.contactName, state.submissionStatus, validators_contactName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: status,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateBusinessEmailField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.businessEmail, state.submissionStatus, validators_businessEmail, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: status,
                                            phone: init.phone,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePhoneField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.phone, state.submissionStatus, validators_phone, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: status,
                                            note: init.note
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNoteField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.note, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            quantity: init.quantity,
                                            colocationSpace: init.colocationSpace,
                                            businessName: init.businessName,
                                            headquartersAddress: init.headquartersAddress,
                                            contactName: init.contactName,
                                            businessEmail: init.businessEmail,
                                            phone: init.phone,
                                            note: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateQuantity: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateQuantityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateColocationSpace: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateColocationSpaceField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateBusinessName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateBusinessNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateHeadquartersAddress: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateHeadquartersAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateContactName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateContactNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateBusinessEmail: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateBusinessEmailField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePhone: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePhoneField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateNote: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNoteField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurQuantity: (function () {
              dispatch("BlurQuantityField");
            }),
          blurColocationSpace: (function () {
              dispatch("BlurColocationSpaceField");
            }),
          blurBusinessName: (function () {
              dispatch("BlurBusinessNameField");
            }),
          blurHeadquartersAddress: (function () {
              dispatch("BlurHeadquartersAddressField");
            }),
          blurContactName: (function () {
              dispatch("BlurContactNameField");
            }),
          blurBusinessEmail: (function () {
              dispatch("BlurBusinessEmailField");
            }),
          blurPhone: (function () {
              dispatch("BlurPhoneField");
            }),
          blurNote: (function () {
              dispatch("BlurNoteField");
            }),
          quantityResult: Formality.exposeFieldResult(state.fieldsStatuses.quantity),
          colocationSpaceResult: Formality.exposeFieldResult(state.fieldsStatuses.colocationSpace),
          businessNameResult: Formality.exposeFieldResult(state.fieldsStatuses.businessName),
          headquartersAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.headquartersAddress),
          contactNameResult: Formality.exposeFieldResult(state.fieldsStatuses.contactName),
          businessEmailResult: Formality.exposeFieldResult(state.fieldsStatuses.businessEmail),
          phoneResult: Formality.exposeFieldResult(state.fieldsStatuses.phone),
          noteResult: Formality.exposeFieldResult(state.fieldsStatuses.note),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.quantity;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.colocationSpace;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.businessName;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.headquartersAddress;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.contactName;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.businessEmail;
              if (typeof tmp$5 === "object") {
                return true;
              }
              var tmp$6 = match.phone;
              if (typeof tmp$6 === "object") {
                return true;
              }
              var tmp$7 = match.note;
              if (typeof tmp$7 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var LocationShowContactForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function LocationShowContactForm$1(props) {
  var setSubmitted = props.setSubmitted;
  var $$location = props.location;
  var initialInput = React.useMemo((function () {
          return {
                  quantity: 1,
                  colocationSpace: "42U Cabinet (Standard)",
                  businessName: "",
                  headquartersAddress: "",
                  contactName: "",
                  businessEmail: "",
                  phone: "",
                  note: ""
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_LocationLead.create({
                    locationId: $$location.id,
                    quantity: output.quantity,
                    colocationSpace: output.colocationSpace,
                    businessName: output.businessName,
                    headquartersAddress: output.headquartersAddress,
                    contactName: output.contactName,
                    businessEmail: output.businessEmail,
                    phone: output.phone,
                    note: output.note,
                    locationLeadType: "Location"
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "LocationShowContactForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "LocationShowContactForm.make"
                              }, "CreateLocationLead::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  cb.notifyOnSuccess(undefined);
                  setSubmitted(true);
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match = form.status;
  if (typeof match !== "object" && match === "Submitted") {
    return null;
  }
  var match$1 = form.status;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsx(ResponseMessage.make, {
          messageType: "Error",
          children: "Something went wrong. Please check the form and try again."
        });
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Qty",
                                        JsxRuntime.jsx("span", {
                                              children: "*",
                                              className: css.required
                                            })
                                      ],
                                      className: css.label
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "locationFormQuanity",
                                      value: String(form.input.quantity),
                                      placeholder: "",
                                      className: css.fieldInput,
                                      onChange: (function ($$event) {
                                          form.updateQuantity((function (input, value) {
                                                  return {
                                                          quantity: value,
                                                          colocationSpace: input.colocationSpace,
                                                          businessName: input.businessName,
                                                          headquartersAddress: input.headquartersAddress,
                                                          contactName: input.contactName,
                                                          businessEmail: input.businessEmail,
                                                          phone: input.phone,
                                                          note: input.note
                                                        };
                                                }), $$event.target.value);
                                        })
                                    }),
                                fieldError(form.quantityResult)
                              ],
                              className: css.field
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        "Colocation Space",
                                        JsxRuntime.jsx("span", {
                                              children: "*",
                                              className: css.required
                                            })
                                      ],
                                      className: css.label
                                    }),
                                JsxRuntime.jsxs(Select.make, {
                                      id: "locationFormColocationSpace",
                                      value: form.input.colocationSpace,
                                      className: css.selectInput,
                                      wrapperClassName: css.rackTypeColocationSpace,
                                      iconSize: "XXS",
                                      iconColor: "GrayText",
                                      onChange: (function ($$event) {
                                          form.updateColocationSpace((function (input, value) {
                                                  return {
                                                          quantity: input.quantity,
                                                          colocationSpace: value,
                                                          businessName: input.businessName,
                                                          headquartersAddress: input.headquartersAddress,
                                                          contactName: input.contactName,
                                                          businessEmail: input.businessEmail,
                                                          phone: input.phone,
                                                          note: input.note
                                                        };
                                                }), $$event.target.value);
                                        }),
                                      children: [
                                        JsxRuntime.jsx(Select.DefaultOption.make, {
                                              children: "Colocation Space"
                                            }),
                                        Belt_Array.mapWithIndex(spaces, (function (index, space) {
                                                if (space === "Unknown") {
                                                  return null;
                                                }
                                                var value = spaceToString(space);
                                                return JsxRuntime.jsx(Select.$$Option.make, {
                                                            value: value,
                                                            children: value
                                                          }, String(index));
                                              }))
                                      ]
                                    }),
                                fieldError(form.colocationSpaceResult)
                              ],
                              className: css.field
                            })
                      ],
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Business Name",
                                      JsxRuntime.jsx("span", {
                                            children: "*",
                                            className: css.required
                                          })
                                    ],
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "locationFormBusinessName",
                                    value: form.input.businessName,
                                    placeholder: "",
                                    className: css.fieldInput,
                                    onChange: (function ($$event) {
                                        form.updateBusinessName((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: value,
                                                        headquartersAddress: input.headquartersAddress,
                                                        contactName: input.contactName,
                                                        businessEmail: input.businessEmail,
                                                        phone: input.phone,
                                                        note: input.note
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.businessNameResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Headquarters Address",
                                      JsxRuntime.jsx("span", {
                                            children: "*",
                                            className: css.required
                                          })
                                    ],
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "locationFormHeadquartersAddress",
                                    value: form.input.headquartersAddress,
                                    placeholder: "",
                                    className: css.fieldInput,
                                    onChange: (function ($$event) {
                                        form.updateHeadquartersAddress((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: input.businessName,
                                                        headquartersAddress: value,
                                                        contactName: input.contactName,
                                                        businessEmail: input.businessEmail,
                                                        phone: input.phone,
                                                        note: input.note
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.headquartersAddressResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Contact Name",
                                      JsxRuntime.jsx("span", {
                                            children: "*",
                                            className: css.required
                                          })
                                    ],
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "locationFormContactName",
                                    value: form.input.contactName,
                                    placeholder: "",
                                    className: css.fieldInput,
                                    onChange: (function ($$event) {
                                        form.updateContactName((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: input.businessName,
                                                        headquartersAddress: input.headquartersAddress,
                                                        contactName: value,
                                                        businessEmail: input.businessEmail,
                                                        phone: input.phone,
                                                        note: input.note
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.contactNameResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Email",
                                      JsxRuntime.jsx("span", {
                                            children: "*",
                                            className: css.required
                                          })
                                    ],
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(TextField.make, {
                                    id: "locationFormBusinessEmail",
                                    value: form.input.businessEmail,
                                    placeholder: "",
                                    className: css.fieldInput,
                                    onChange: (function ($$event) {
                                        form.updateBusinessEmail((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: input.businessName,
                                                        headquartersAddress: input.headquartersAddress,
                                                        contactName: input.contactName,
                                                        businessEmail: value,
                                                        phone: input.phone,
                                                        note: input.note
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.businessEmailResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("span", {
                                          children: "*",
                                          className: css.required
                                        }),
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(TelephoneField.make, {
                                    id: "signUp-form--phone",
                                    value: form.input.phone,
                                    defaultCountry: "us",
                                    onChange: (function (phoneValue, selectedCountry) {
                                        var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                        form.updatePhone((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: input.businessName,
                                                        headquartersAddress: input.headquartersAddress,
                                                        contactName: input.contactName,
                                                        businessEmail: input.businessEmail,
                                                        phone: value,
                                                        note: input.note
                                                      };
                                              }), "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, " ", phoneValue).trim()));
                                      }),
                                    preferredCountries: [
                                      "us",
                                      "ca",
                                      "uk"
                                    ]
                                  }),
                              fieldError(form.phoneResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Note (Optional)",
                                    className: css.label
                                  }),
                              JsxRuntime.jsx(Textarea.make, {
                                    id: "locationFormNote",
                                    value: form.input.note,
                                    placeholder: "",
                                    className: css.fieldInput,
                                    onChange: (function ($$event) {
                                        form.updateNote((function (input, value) {
                                                return {
                                                        quantity: input.quantity,
                                                        colocationSpace: input.colocationSpace,
                                                        businessName: input.businessName,
                                                        headquartersAddress: input.headquartersAddress,
                                                        contactName: input.contactName,
                                                        businessEmail: input.businessEmail,
                                                        phone: input.phone,
                                                        note: value
                                                      };
                                              }), $$event.target.value);
                                      })
                                  }),
                              fieldError(form.noteResult)
                            ],
                            className: css.field
                          }),
                      className: css.fieldRow
                    }),
                tmp,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "LG",
                            color: "Primary",
                            expanded: true,
                            disabled: form.submitting,
                            visuallyDisabled: false,
                            busy: form.submitting,
                            submit: true,
                            children: "Submit"
                          }),
                      className: css.fieldRow
                    })
              ],
              className: css.formContainer,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var ReactTelInputData;

var make = LocationShowContactForm$1;

export {
  css ,
  ReactTelInputData ,
  spaces ,
  spaceToString ,
  LocationShowContactForm ,
  make ,
}
/* css Not a pure module */
