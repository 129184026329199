// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconColocationFacility(props) {
  var __viewBoxSize = props.viewBoxSize;
  var __opacity = props.opacity;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Colocation Facility";
  var opacity = __opacity !== undefined ? __opacity : "1.0";
  var viewBoxSize = __viewBoxSize !== undefined ? __viewBoxSize : "40";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: viewBoxSize,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M34 4H6C4.89543 4 4 4.89543 4 6V13C4 14.1046 4.89543 15 6 15H34C35.1046 15 36 14.1046 36 13V6C36 4.89543 35.1046 4 34 4ZM6 13V6H34V13H6Z",
                            fill: "#18C3BB",
                            fillRule: "evenodd"
                          }),
                      JsxRuntime.jsx("circle", {
                            cx: "9.5",
                            cy: "9.5",
                            fill: "#933CDD",
                            r: "1.5"
                          }),
                      JsxRuntime.jsx("circle", {
                            cx: "14.5",
                            cy: "9.5",
                            fill: "#933CDD",
                            r: "1.5"
                          }),
                      JsxRuntime.jsx("path", {
                            clipRule: "evenodd",
                            d: "M34 17H6C4.89543 17 4 17.8954 4 19V26C4 27.1046 4.89543 28 6 28H34C35.1046 28 36 27.1046 36 26V19C36 17.8954 35.1046 17 34 17ZM6 26V19H34V26H6Z",
                            fill: "#18C3BB",
                            fillRule: "evenodd"
                          }),
                      JsxRuntime.jsx("circle", {
                            cx: "9.5",
                            cy: "22.5",
                            fill: "#933CDD",
                            r: "1.5"
                          }),
                      JsxRuntime.jsx("circle", {
                            cx: "14.5",
                            cy: "22.5",
                            fill: "#933CDD",
                            r: "1.5"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M4 35C4 34.4477 4.44772 34 5 34H19V31C19 30.4477 19.4477 30 20 30C20.5523 30 21 30.4477 21 31V34H35C35.5523 34 36 34.4477 36 35C36 35.5523 35.5523 36 35 36H5C4.44772 36 4 35.5523 4 35Z",
                            fill: "#933CDD"
                          })
                    ],
                    opacity: opacity
                  })
            });
}

var make = IconColocationFacility;

export {
  make ,
}
/* Icon Not a pure module */
