// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconPower from "../../../../../../styleguide/icons/IconPower.res.js";
import * as IconAirplane from "../../../../../../styleguide/icons/IconAirplane.res.js";
import * as IconBuilding from "../../../../../../styleguide/icons/IconBuilding.res.js";
import * as IconProducts from "../../../../../../styleguide/icons/IconProducts.res.js";
import * as IconColocation from "../../../../../../styleguide/icons/IconColocation.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowSidebarStatScss from "./LocationShowSidebarStat.scss";

var css = LocationShowSidebarStatScss;

function iconToId(icon) {
  switch (icon) {
    case "TotalSpace" :
        return "totalSpace";
    case "ColocationSpace" :
        return "colocationSpace";
    case "Power" :
        return "power";
    case "Products" :
        return "products";
    case "Airport" :
        return "airport";
    
  }
}

function LocationShowSidebarStat(props) {
  var stat = props.stat;
  var icon = props.icon;
  var tmp;
  switch (icon) {
    case "TotalSpace" :
        tmp = JsxRuntime.jsx(IconBuilding.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "ColocationSpace" :
        tmp = JsxRuntime.jsx(IconColocation.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "Power" :
        tmp = JsxRuntime.jsx(IconPower.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "Products" :
        tmp = JsxRuntime.jsx(IconProducts.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    case "Airport" :
        tmp = JsxRuntime.jsx(IconAirplane.make, {
              size: "SM",
              color: "DarkerGray"
            });
        break;
    
  }
  var tmp$1;
  switch (icon) {
    case "TotalSpace" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " sqft "
                    }),
                "total space"
              ]
            });
        break;
    case "ColocationSpace" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " sqft "
                    }),
                "colocation space"
              ]
            });
        break;
    case "Power" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " MW "
                    }),
                "total power"
              ]
            });
        break;
    case "Products" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " colocation "
                    }),
                "products"
              ]
            });
        break;
    case "Airport" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("strong", {
                      children: stat + " miles "
                    }),
                "to nearest airport"
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.statIcon
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: css.statInfo,
                      id: "statInfo"
                    })
              ],
              className: css.statContainer,
              id: iconToId(icon)
            });
}

var make = LocationShowSidebarStat;

export {
  css ,
  iconToId ,
  make ,
}
/* css Not a pure module */
