// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconInternetExchange(props) {
  var __viewBoxSize = props.viewBoxSize;
  var __opacity = props.opacity;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Internet Exchange";
  var opacity = __opacity !== undefined ? __opacity : "1.0";
  var viewBoxSize = __viewBoxSize !== undefined ? __viewBoxSize : "40";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: viewBoxSize,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M8 12H28.5L21.75 5.25L23 4L31 12L32 13L31 14L23 22L21.75 20.2517L28.5 14H8V12Z",
                            fill: "#933CDD"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M32 26H11.5L18.25 19.25L17 18L9 26L8 27L9 28L17 36L18.25 34.2517L11.5 28H32V26Z",
                            fill: "#18C3BB"
                          })
                    ],
                    opacity: opacity
                  })
            });
}

var make = IconInternetExchange;

export {
  make ,
}
/* Icon Not a pure module */
