// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Api_Location from "../../../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowRelatedLocation from "./location/LocationShowRelatedLocation.res.js";
import * as LocationShowRelatedLocationsScss from "./LocationShowRelatedLocations.scss";

var css = LocationShowRelatedLocationsScss;

function LocationShowRelatedLocations(props) {
  var relatedLocations = props.relatedLocations;
  var country = props.country;
  var provider = props.provider;
  var locationId = props.locationId;
  var initialState_currentPage = relatedLocations.currentPage;
  var initialState_locations = relatedLocations.locations;
  var initialState_totalPages = relatedLocations.totalPages;
  var initialState_totalLocations = relatedLocations.totalLocations;
  var initialState = {
    currentPage: initialState_currentPage,
    locations: initialState_locations,
    totalPages: initialState_totalPages,
    totalLocations: initialState_totalLocations,
    fetching: false
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      currentPage: state.currentPage,
                      locations: state.locations,
                      totalPages: state.totalPages,
                      totalLocations: state.totalLocations,
                      fetching: true
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        $$Promise.wait(Api_Location.relatedLocations(param.state.currentPage, locationId), (function (x) {
                                if (x.TAG === "Ok") {
                                  return dispatch({
                                              TAG: "UpdateLocations",
                                              _0: x._0
                                            });
                                } else {
                                  return SentryLogger.error1({
                                              rootModule: "LocationShowRelatedLocations",
                                              subModulePath: /* [] */0,
                                              value: "make",
                                              fullPath: "LocationShowRelatedLocations.make"
                                            }, "RelatedLocations::FetchRelatedLocations::Error", [
                                              "Error",
                                              x._0
                                            ]);
                                }
                              }));
                      })
                  };
          }
          if (action.TAG === "Paginate") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      currentPage: action._0,
                      locations: state.locations,
                      totalPages: state.totalPages,
                      totalLocations: state.totalLocations,
                      fetching: state.fetching
                    },
                    _1: (function (param) {
                        param.dispatch("FetchLocations");
                      })
                  };
          }
          var relatedLocations = action._0;
          return {
                  TAG: "Update",
                  _0: {
                    currentPage: state.currentPage,
                    locations: relatedLocations.locations,
                    totalPages: relatedLocations.totalPages,
                    totalLocations: relatedLocations.totalLocations,
                    fetching: false
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = relatedLocations.totalLocations;
  var countText = match$1 !== 0 ? (
      match$1 !== 1 ? String(relatedLocations.totalLocations) + " Locations" : "1 Location"
    ) : "No Locations";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(H2.make, {
                              className: css.relatedLocationsTitle,
                              children: [
                                provider.name + " Locations in " + country.name + " ",
                                JsxRuntime.jsx("span", {
                                      children: "(" + countText + ")",
                                      className: css.locationsCount
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Button.AsLink.make, {
                              href: Routes_Provider.Country.show(provider.slug, country.slug),
                              size: "SM",
                              color: "Teal",
                              buttonClassName: css.relatedLocationsButton,
                              children: "View on Map"
                            })
                      ],
                      className: css.relatedLocationsHeader
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(state.locations, (function (index, $$location) {
                              return JsxRuntime.jsx(LocationShowRelatedLocation.make, {
                                          location: $$location
                                        }, String(index));
                            })),
                      className: css.relatedLocationsList
                    }),
                state.totalPages > 1 ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Pagination.make, {
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              onPageClick: (function (pageNum) {
                                  dispatch({
                                        TAG: "Paginate",
                                        _0: pageNum
                                      });
                                })
                            }),
                        className: css.relatedLocationsPagination
                      }) : null
              ],
              className: css.relatedLocations,
              id: "relatedLocations"
            });
}

var make = LocationShowRelatedLocations;

export {
  css ,
  make ,
}
/* css Not a pure module */
