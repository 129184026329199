// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Picture from "../../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ImageGallery from "../../../../../../../styleguide/components/ImageGallery/ImageGallery.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentMediaScss from "./LocationShowContentMedia.scss";

var css = LocationShowContentMediaScss;

var initialState = {
  galleryVisibility: "Hidden",
  currentImageIndex: undefined
};

function LocationShowContentMedia(props) {
  var images = props.images;
  var reducer = function (state, action) {
    var match = state.galleryVisibility;
    if (match === "Visible") {
      return {
              galleryVisibility: "Hidden",
              currentImageIndex: undefined
            };
    } else {
      return {
              galleryVisibility: "Visible",
              currentImageIndex: action._0
            };
    }
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.galleryVisibility;
  var tmp;
  tmp = match$1 === "Visible" ? JsxRuntime.jsx(ImageGallery.make, {
          images: images,
          imageIndex: Belt_Option.getExn(state.currentImageIndex),
          onClick: (function (param) {
              dispatch({
                    TAG: "ToggleGalleryVisibility",
                    _0: undefined
                  });
            })
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                images.length !== 0 ? JsxRuntime.jsx("div", {
                        children: Belt_Array.mapWithIndex(images, (function (index, image) {
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Picture.make, {
                                                    src: image.url,
                                                    large: [
                                                      320,
                                                      240
                                                    ],
                                                    className: css.locationMediaImage,
                                                    fallbackSrc: $$Location.imageNotFoundUrl
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("div", {
                                                          children: "View image",
                                                          className: css.mediaImageOverlayText
                                                        }),
                                                    className: css.mediaImageOverlay
                                                  })
                                            ],
                                            className: css.mediaImageContainer,
                                            id: "imageTile",
                                            onClick: (function (param) {
                                                dispatch({
                                                      TAG: "ToggleGalleryVisibility",
                                                      _0: index
                                                    });
                                              })
                                          }, "image-" + String(index));
                              })),
                        className: css.mediaImagesContainer,
                        id: "mediaImagesContainer"
                      }) : JsxRuntime.jsx("div", {
                        children: "No images have been uploaded for this location.",
                        className: css.noImages
                      }),
                tmp
              ]
            });
}

var make = LocationShowContentMedia;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
