// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconTechnologies(props) {
  var __opacity = props.opacity;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Technologies";
  var opacity = __opacity !== undefined ? __opacity : "1.0";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "40",
                                                    width: "40",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "10",
                                                    y: "10"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M18,54 L21,54 L21,59.3333333 C21,59.7015232 20.7015232,60 20.3333333,60 L18.6666667,60 C18.2984768,60 18,59.7015232 18,59.3333333 L18,54 Z",
                                                    fill: "#18C3BB",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "6",
                                                    width: "3",
                                                    fill: "#18C3BB",
                                                    x: "39",
                                                    y: "54"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M18.6666667,0 L20.3333333,0 C20.7015232,-6.76353751e-17 21,0.298476833 21,0.666666667 L21,6 L18,6 L18,0.666666667 C18,0.298476833 18.2984768,4.00702282e-16 18.6666667,3.33066907e-16 Z",
                                                    fill: "#18C3BB"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M39.6666667,0 L41.3333333,0 C41.7015232,-6.76353751e-17 42,0.298476833 42,0.666666667 L42,6 L39,6 L39,0.666666667 C39,0.298476833 39.2984768,4.00702282e-16 39.6666667,3.33066907e-16 Z",
                                                    fill: "#18C3BB"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M54,18 L59.3333333,18 C59.7015232,18 60,18.2984768 60,18.6666667 L60,20.3333333 C60,20.7015232 59.7015232,21 59.3333333,21 L54,21 L54,18 Z",
                                                    fill: "#18C3BB"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M54,39 L59.3333333,39 C59.7015232,39 60,39.2984768 60,39.6666667 L60,41.3333333 C60,41.7015232 59.7015232,42 59.3333333,42 L54,42 L54,39 Z",
                                                    fill: "#18C3BB"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M0.666666667,18 L6,18 L6,21 L0.666666667,21 C0.298476833,21 3.78157157e-16,20.7015232 3.33066907e-16,20.3333333 L0,18.6666667 C-4.50902501e-17,18.2984768 0.298476833,18 0.666666667,18 Z",
                                                    fill: "#18C3BB"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "22",
                                                    width: "22",
                                                    rx: "2",
                                                    stroke: "#18C3BB",
                                                    strokeWidth: "2",
                                                    x: "19",
                                                    y: "19"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M0.666666667,39 L6,39 L6,42 L0.666666667,42 C0.298476833,42 3.78157157e-16,41.7015232 3.33066907e-16,41.3333333 L0,39.6666667 C-4.50902501e-17,39.2984768 0.298476833,39 0.666666667,39 Z",
                                                    fill: "#18C3BB"
                                                  })
                                            ],
                                            id: "Group-10"
                                          }),
                                      id: "Icon-/-Big-/-Technologies",
                                      transform: "translate(1080.000000, 640.000000)"
                                    }),
                                id: "Agent-Program",
                                fillRule: "nonzero",
                                transform: "translate(-1080.000000, -640.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          opacity: opacity,
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconTechnologies;

export {
  make ,
}
/* Icon Not a pure module */
